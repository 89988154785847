export const REGISTER_START = "REGISTER_START";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";

export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const FORGOT_PASSWORD_START = "FORGOT_PASSWORD_START";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";

export const STRAPI_FORGOT_PASSWORD_START = "STRAPI_FORGOT_PASSWORD_START";
export const STRAPI_FORGOT_PASSWORD_SUCCESS = "STRAPI_FORGOT_PASSWORD_SUCCESS";
export const STRAPI_FORGOT_PASSWORD_FAIL = "STRAPI_FORGOT_PASSWORD_FAIL";

export const LOGOUT_START = "LOGOUT_START";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";

export const GOOGLE_LOGIN_START = "GOOGLE_LOGIN_START";
export const GOOGLE_LOGIN_SUCCESS = "GOOGLE_LOGIN_SUCCESS";
export const GOOGLE_LOGIN_FAIL = "GOOGLE_LOGIN_FAIL";

export const FACEBOOK_LOGIN_START = "FACEBOOK_LOGIN_START";
export const FACEBOOK_LOGIN_SUCCESS = "FACEBOOK_LOGIN_SUCCESS";
export const FACEBOOK_LOGIN_FAIL = "FACEBOOK_LOGIN_FAIL";

export const APPLE_LOGIN_START = "APPLE_LOGIN_START";
export const APPLE_LOGIN_SUCCESS = "APPLE_LOGIN_SUCCESS";
export const APPLE_LOGIN_FAIL = "APPLE_LOGIN_FAIL";

export const TWITTER_LOGIN_START = "TWITTER_LOGIN_START";
export const TWITTER_LOGIN_SUCCESS = "TWITTER_LOGIN_SUCCESS";
export const TWITTER_LOGIN_FAIL = "TWITTER_LOGIN_FAIL";

export const STRAPI_LOGIN_START = "STRAPI_LOGIN_START";
export const STRAPI_LOGIN_SUCCESS = "STRAPI_LOGIN_SUCCESS";
export const STRAPI_LOGIN_FAIL = "STRAPI_LOGIN_FAIL";

export const STRAPI_REGISTER_START = "STRAPI_REGISTER_START";
export const STRAPI_REGISTER_SUCCESS = "STRAPI_REGISTER_SUCCESS";
export const STRAPI_REGISTER_FAIL = "STRAPI_REGISTER_FAIL";

export const SET_USER = "SET_USER";
